// @flow

import React from 'react';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { LayoutDefault } from 'shared_components/layouts/Default';
import { LoginForm } from 'shared_components/forms/login/Form';
import { HOCFormLogin } from 'shared_hocs/forms/Login';
import { AuthContainer, AuthLogo, AuthBody } from 'shared_components/auth/containers/auth/Auth';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { SocialContainer } from 'shared_components/auth/containers/social/Social';
import { HOCSocialLogin } from 'shared_hocs/auth/SocialLogin';
import { HOCAuthParams } from 'shared_hocs/auth/Params';

const HOCLoginSocial = HOCSocialLogin(SocialContainer);
const HOCLoginForm = HOCFormLogin(LoginForm);

type Props = {
  authParams: Object,
  location: Object,
  currentLocale: Object,
};

/**
 * LoginPage
 *
 * @param {Props} props
 * @returns {Node}
 */
const LoginPage = ({ authParams, location, currentLocale }: Props) => (
  <LayoutDefault hasFooter hasHeader showRecentArtworkWidget={false}>
    <AuthContainer>
      {(onLoadingChange) => (
        <React.Fragment>
          <AuthLogo />
          <AuthBody>
            <HOCLoginSocial onLoadingChange={onLoadingChange} authParams={authParams} />
            <HOCLoginForm
              onLoadingChange={onLoadingChange}
              authPath={UrlAssembler.byRouteKey('register', {
                ...location,
                locale: currentLocale,
              })}
              authParams={authParams}
            />
          </AuthBody>
        </React.Fragment>
      )}
    </AuthContainer>
  </LayoutDefault>
);

export default HOCAuthParams(withTranslatedRouter(LoginPage));
